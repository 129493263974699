@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100;0,400;0,700;1,100;1,400;1,700&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'JetBrains Mono', monospace;

  height: 100%;
  width: 100%;
}

* {
  box-sizing: border-box;
}

#root {
  width: 100vw;
  height: 100%;
  min-height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
}
